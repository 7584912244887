import C from "./Dashboard.module.css";

import { Link } from "@tanstack/react-router";
import { apiDatetimeToDate } from "@warrenio/api-spec/conversion";
import { exhaustiveSwitchCheck } from "@warrenio/utils/unreachable";
import { formatDistance } from "date-fns";
import { Suspense, type ReactNode } from "react";
import { sortBy } from "remeda";
import { Badge } from "../../components/Badge.tsx";
import { SeparatedBy } from "../../components/SeparatedBy.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { Loading } from "../../components/loading/Loading.tsx";
import { ReportingErrorBoundary } from "../../dev/ReportingErrorBoundary.tsx";
import { cn } from "../../utils/classNames.ts";
import { useQueryResultAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { getResourceIconClass, getResourceLink, getResourceTypeName } from "../api/resourceTypes.tsx";
import { ErrorFallback } from "../main/ErrorFallback.tsx";
import { MetalStatusBadge } from "../metal/status/MetalStatus.tsx";
import { ResourceStatusBadge } from "../network/ResourceStatusBadge.tsx";
import { getResourceName } from "../resource/getResourceName.tsx";
import { allResourcesAtom, type RecentResource } from "./recentQuery.ts";

export function RecentItem({ item }: { item: RecentResource }) {
    const action = getResourceLink(item);

    return (
        <Link className={cn(C.ListItem, C.Hoverable)} {...action}>
            <div className={C.Icon}>
                <MaskIcon className={cn("size-1.25rem text-primary", getResourceIconClass(item.$type))} />
            </div>

            <div className={C.Content}>
                {getResourceName(item)}
                <div className="color-muted font-size-small">
                    {item.created_at
                        ? formatDistance(apiDatetimeToDate(item.created_at), new Date(), {
                              addSuffix: true,
                          })
                        : "-"}
                    <span className="inline-block px-1">&bull;</span>
                    {getResourceTypeName(item.$type)}
                </div>
            </div>

            <div className={C.Status}>
                <ResourceStatus item={item} />
            </div>

            <div className={C.Btn}>
                <MaskIcon className="jp-arrow-thin-right-icon size-0.75rem" />
            </div>
        </Link>
    );
}

export interface RecentlyModifiedProps {
    items: ReactNode[];
}

export function RecentlyModifiedLayout({ items }: RecentlyModifiedProps) {
    // TODO: Add services
    return (
        <div className={C.Modified}>
            <div className={cn(C.BlockTitle, "pl-2")}>
                <h2>Recent</h2>
            </div>

            <div className={C.Block}>
                {items?.length ? (
                    <div className={C.Scroll}>
                        {items}

                        {items.length > 9 ? (
                            <div className={C.ListItem}>
                                <div className="text-muted">Showing 10 latest items</div>
                            </div>
                        ) : null}
                    </div>
                ) : (
                    <div className={cn(C.Empty, "color-muted")}>
                        <div>
                            <MaskIcon className="jp-cactus-icon size-4rem" />
                            <p className="pt-2">Take some action and you'll never see this cactus again.</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export interface RecentlyModifiedPropsLoaderProps extends Omit<RecentlyModifiedProps, "items"> {}

function RecentlyModifiedLoader({ ...props }: RecentlyModifiedPropsLoaderProps) {
    //#region Hooks
    const { data: allItems = [] } = useQueryResultAtom(allResourcesAtom);
    //#endregion

    const items = sortBy(allItems, [(item) => apiDatetimeToDate(item.created_at).getTime(), "desc"]).slice(0, 10);

    return (
        <RecentlyModifiedLayout
            {...props}
            items={items.map((item: RecentResource, index: number) => (
                // TODO: Use proper `key` (create a `getResourceKey()` or similar)
                <RecentItem key={index} item={item} />
            ))}
        />
    );
}

export function RecentlyModified(props: RecentlyModifiedPropsLoaderProps) {
    return (
        <ReportingErrorBoundary
            ErrorComponent={({ error }: { error: unknown }) => (
                <RecentlyModifiedLayout {...props} items={[<ErrorFallback key="error" error={error} />]} />
            )}
        >
            <Suspense
                fallback={
                    <RecentlyModifiedLayout {...props} items={[<Loading key="loading" delay={0} icon="none" />]} />
                }
            >
                <RecentlyModifiedLoader {...props} />
            </Suspense>
        </ReportingErrorBoundary>
    );
}

function ResourceStatus({ item }: { item: RecentResource }) {
    const { $type } = item;
    switch ($type) {
        case "api_token":
            // XXX: Is this necessary/useful?
            return <Badge color="success">Active</Badge>;

        case "access_delegation": {
            let acceptedSum = 0;
            let pendingSum = 0;

            for (const s of item.impersonations) {
                if (s.accepted_at) {
                    acceptedSum++;
                } else {
                    pendingSum++;
                }
            }

            return acceptedSum && pendingSum ? (
                <SeparatedBy separator=" ">
                    {!!acceptedSum && <Badge color="success">{acceptedSum} accepted</Badge>}
                    {!!pendingSum && <Badge color="warning">{pendingSum} pending</Badge>}
                </SeparatedBy>
            ) : null;
        }
        case "access_impersonation":
            // TODO: Re-use text/translation/code from existing Delegation components
            return item.is_accepted ? <Badge color="success">accepted</Badge> : <Badge color="warning">pending</Badge>;

        case "ip_address":
            // TODO: Re-use text/translation/code from existing Network components
            return item.assigned_to ? <Badge color="success">assigned</Badge> : <Badge color="error">unassigned</Badge>;

        case "load_balancer":
            // XXX: Is this necessary/useful/correct?
            return <Badge color="success">Online</Badge>;

        case "virtual_machine":
        case "managed_service":
            return <ResourceStatusBadge item={item} />;

        case "vpc":
        case "bucket":
            return undefined;

        case "bare_metal":
            return <MetalStatusBadge value={item.status} />;

        default:
            exhaustiveSwitchCheck($type);
    }
}
