import C from "./Dashboard.module.css";

import { Link as RouterLink } from "@tanstack/react-router";
import type { VmImage } from "@warrenio/api-spec/spec.oats.gen";
import { type ReactElement, Suspense } from "react";
import type { Settings } from "react-slick";
import { WButton } from "../../components/button/WButton.tsx";
import { WCarousel } from "../../components/carousel/WCarousel.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { Loading } from "../../components/loading/Loading.tsx";
import { cn } from "../../utils/classNames.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { getAppCreateLink } from "../api/resourceCreateLinks.ts";
import { VmImageIcon } from "../compute/VmImageIcon.tsx";
import { availableImagesAtom } from "../compute/vmImagesQuery.ts";

export interface AppCatalogProps {
    items: ReactElement[];
    more?: boolean;
    loading?: boolean;
}

// TODO/M: Extract common components with ManagedServicesItem
export function AppCatalogItem({ item, more }: { item: VmImage; more?: boolean }) {
    const osVersion = item.versions.find((v) => v.published)!.os_version;
    const icon = <VmImageIcon image={item} className={more ? "size-5rem" : "size-2.5rem"} />;
    const createLink = getAppCreateLink(item.os_name, osVersion);
    return more ? (
        <div className={cn(C.CreateLink, more ? C.wideApp : C.Hoverable)}>
            {icon}
            <div className={C.ContentApp}>
                <div className={cn(C.Content, "font-size-title", "text-ellipsis")}>{item.display_name}</div>

                <WButton
                    color="primary"
                    icon="jp-arrow-thin-right-icon"
                    iconSide="right"
                    variant="ghost"
                    size="bar"
                    action={createLink}
                >
                    Create App
                </WButton>
            </div>
        </div>
    ) : (
        <RouterLink {...createLink}>
            <div className={cn(C.CreateLink, C.Hoverable)}>
                {icon}
                <div className={C.Content}>{item.display_name}</div>

                <div className={C.Btn}>
                    <MaskIcon className="jp-arrow-thin-right-icon size-0.75rem" />
                </div>
            </div>
        </RouterLink>
    );
}

// TODO/M: Extract common components with ManagedServicesCustom
export function AppCatalogCustom({ more, items, loading }: AppCatalogProps) {
    const settings: Settings = {
        dots: true,
        infinite: false,
        rows: 2,
        customPaging: () => <div className="dot" />,
    };

    return (
        <div className={cn(C.CreateLinks, !more && !loading && C.grid)}>
            {items.length ? (
                more ? (
                    <WCarousel settings={settings} slideWidth={360} slideClassName={C.CreateSlider} showArrowsMin={2}>
                        {items}
                    </WCarousel>
                ) : (
                    items
                )
            ) : (
                <div className={C.Empty}>
                    <i>No results found</i>
                </div>
            )}
        </div>
    );
}

export interface AppCatalogPropsLoaderProps extends Omit<AppCatalogProps, "items"> {}

function AppCatalogLoader({ ...props }: AppCatalogPropsLoaderProps) {
    const images = useSuspenseQueryAtom(availableImagesAtom);
    const filtered = images.filter((s: VmImage) => s.is_app_catalog);
    return (
        <AppCatalogCustom
            items={filtered.map((item: VmImage, index: number) => (
                <AppCatalogItem key={index} item={item} more={props.more} />
            ))}
            {...props}
        />
    );
}

export function AppCatalog(props: AppCatalogPropsLoaderProps) {
    return (
        <Suspense
            fallback={
                <AppCatalogCustom {...props} loading items={[<Loading key="loading" delay={0} icon="none" white />]} />
            }
        >
            <AppCatalogLoader {...props} />
        </Suspense>
    );
}
