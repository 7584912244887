import C from "./Dashboard.module.css";

import { Suspense } from "react";
import { IsoCerts } from "../../components/IsoCerts.tsx";
import { useConfig } from "../../config.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { useTitle } from "../../utils/react/useTitle.ts";
import { eBillingAccountQueryAtom } from "../billing/billingAccountQuery.ts";
import { ReferralProgram } from "../billing/referral/ReferralProgram.tsx";
import { SupportLink } from "../support/SupportLink.tsx";
import { useThemeProps } from "../theme/useTheme.ts";
import { BillingAccounts } from "./BillingAccounts.tsx";
import { DashboardNotices } from "./DashboardNotices.tsx";
import { RecentlyModified } from "./RecentlyModified.tsx";
import { StartSomethingNew } from "./StartSomethingNew.tsx";
import { UserAccess } from "./UserAccess.tsx";
import { WelcomeBlock } from "./WelcomeBlock.tsx";
import { WelcomeModal } from "./WelcomeModal.tsx";

function SupportBlock() {
    const { isoCertificates, isoCertificatesHiddenDesktop } = useThemeProps();

    const support = (
        <p className="color-muted text-center font-size-small">
            If you have any questions, found a bug or have a feature request feel free to contact us through{" "}
            <SupportLink>support</SupportLink>.
        </p>
    );

    return (
        <div>
            {isoCertificates?.length && !isoCertificatesHiddenDesktop ? (
                <div className={C.Support}>
                    <IsoCerts />

                    {support}
                </div>
            ) : (
                support
            )}
        </div>
    );
}

export default function Dashboard() {
    useTitle("Dashboard");

    const { referralCodeEnabled } = useConfig();

    return (
        <div className={C.DashboardHolder}>
            <Suspense>
                <WelcomeModalLoader />
            </Suspense>

            <DashboardNotices />

            <WelcomeBlock />

            <StartSomethingNew />

            <div className={C.Recent}>
                <RecentlyModified />
                <UserAccess />
                <BillingAccounts />
            </div>

            {referralCodeEnabled && (
                <ReferralProgram copy="You can earn and give free credits by sharing your unique referral code." />
            )}

            <SupportBlock />
        </div>
    );
}

function WelcomeModalLoader() {
    const accounts = useSuspenseQueryAtom(eBillingAccountQueryAtom);
    // TODO: Extract to centralized storage logic
    const firstAccount = sessionStorage.getItem("firstAccount");
    return (
        !accounts.size &&
        !firstAccount && (
            <WelcomeModal
                isVisible={true}
                // TODO/J: Should probably be in root component, not dashboard?
            />
        )
    );
}
