import C from "./Dashboard.module.css";

import { Link as RouterLink } from "@tanstack/react-router";
import type { ServiceImage } from "@warrenio/api-spec/spec.oats.gen";
import { Suspense, type ReactElement } from "react";
import type { Settings } from "react-slick";
import { WButton } from "../../components/button/WButton.tsx";
import { WCarousel } from "../../components/carousel/WCarousel.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { Loading } from "../../components/loading/Loading.tsx";
import { cn } from "../../utils/classNames.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { getServiceCreateLink } from "../api/resourceCreateLinks.ts";
import { ServiceImageIcon } from "../services/ServiceImageIcon.tsx";
import { availableServiceImagesAtom } from "../services/serviceImagesQuery.ts";

export interface ManagedServicesProps {
    items: ReactElement[];
    more?: boolean;
    loading?: boolean;
}

export function ManagedServicesItem({ item, more }: { item: ServiceImage; more?: boolean }) {
    const osVersion = item.versions[0];
    const icon = <ServiceImageIcon image={item} className={more ? "size-5rem" : "size-2.5rem"} />;
    const createLink = getServiceCreateLink(item.service_name, osVersion);

    return more ? (
        <div className={cn(C.CreateLink, C.wideApp)}>
            {icon}

            <div className={C.ContentApp}>
                <div className={cn(C.Content, "font-size-title", "text-ellipsis")}>{item.display_name}</div>
                <p className="pt-2 color-muted font-size-small">{item.description}</p>
                <WButton
                    color="primary"
                    icon="jp-arrow-thin-right-icon"
                    iconSide="right"
                    variant="ghost"
                    size="bar"
                    action={createLink}
                >
                    Create Managed Service
                </WButton>
            </div>
        </div>
    ) : (
        <RouterLink className={cn(C.CreateLink, C.Hoverable)} {...createLink}>
            {icon}

            <div className={C.Content}>{item.display_name}</div>
            <div className={C.Btn}>
                <MaskIcon className="jp-arrow-thin-right-icon size-0.75rem" />
            </div>
        </RouterLink>
    );
}

export function ManagedServicesCustom({ more, items, loading }: ManagedServicesProps) {
    const settings: Settings = {
        dots: true,
        infinite: false,
        rows: 1,
        customPaging: () => <div className="dot" />,
    };

    return (
        <div className={cn(C.CreateLinks, !more && !loading && C.grid)}>
            {items.length ? (
                more ? (
                    <WCarousel settings={settings} slideWidth={360} slideClassName={C.CreateSlider} showArrowsMin={2}>
                        {items}
                    </WCarousel>
                ) : (
                    items
                )
            ) : (
                <div className={C.Empty}>
                    <i>No results found</i>
                </div>
            )}
        </div>
    );
}

export interface ManagedServicesPropsLoaderProps extends Omit<ManagedServicesProps, "items"> {}

function ManagedServicesLoader({ ...props }: ManagedServicesPropsLoaderProps) {
    const items = useSuspenseQueryAtom(availableServiceImagesAtom);

    return (
        <ManagedServicesCustom
            items={items.map((item: ServiceImage) => (
                <ManagedServicesItem key={item.uuid} item={item} more={props.more} />
            ))}
            {...props}
        />
    );
}

export function ManagedServices(props: ManagedServicesPropsLoaderProps) {
    return (
        <Suspense
            fallback={
                <ManagedServicesCustom
                    {...props}
                    loading
                    items={[<Loading key="loading" delay={0} icon="none" white />]}
                />
            }
        >
            <ManagedServicesLoader {...props} />
        </Suspense>
    );
}
